import Vue from 'vue'
import VueRouter from 'vue-router'
import ExpertList from "../views/ExpertList.vue";
import ExpertItem from "../views/ExpertItem.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "list",
    component: ExpertList,
  },
  {
    path: "/:id",
    name: "item",
    component: ExpertItem,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
