<template>
  <v-container>
    <v-sheet elevation="12" rounded class="pa-3">
      <v-card-title>
        Список экспертов
        <v-spacer></v-spacer>
        <div class="d-flex justify-space-between">
          <v-text-field
            class="pr-3"
            v-model="searchString"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
          ></v-text-field>
        </div>
      </v-card-title>
      <v-data-table
        disable-sort
        :items="experts"
        :loading="loading"
        :server-items-length="count"
        :items-per-page.sync="perPage"
        :headers="headers"
        :page.sync="page"
        class="border"
        loading-text="Загрузка... Пожалуйста подождите"
        no-data-text="Результатов нет"
        no-results-text="Результатов нет"
        :footer-props="{
          'items-per-page-text': 'Количество',
          'items-per-page-options': [5, 10, 20,100],
        }"
      >
        <template
          v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
        >
          {{ pageStart }}-{{ pageStop }} из {{ itemsLength }}
        </template>
        <template v-slot:no-data> Нет элементов </template>
        <template v-slot:item.name="{ item }">
          <div class="text-left">
            {{ item.name }}
          </div>
        </template>
        <template v-slot:item.expertsKindOfActivities="{ item }">
          <div class="text-left">
            <v-list-item v-for="kind in item.expertsKindOfActivities" :key="kind.id">
              {{ kind.name }}
            </v-list-item>
          </div>
        </template>
        <template v-slot:item.expertsCertificates="{ item }">
          <div class="text-left">
            <div v-for="kind in item.expertsKindOfActivities" :key="kind.id">
              <v-list-item
                v-for="serts in kind.expertsCertificates"
                :key="serts.id"
              >
                {{serts.lines.map(el => el.name).join(', ')}}
              </v-list-item>
            </div>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="text-center">
            <v-btn :to="'/' + item.id" color="green" dark>Перейти</v-btn>
          </div>
        </template>
        <template v-slot:loading>
          <v-skeleton-loader
            class="mx-auto"
            type="table-tbody"
          ></v-skeleton-loader>
        </template>
      </v-data-table>
          <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :total-visible="15"
        :length="Math.ceil(parseInt(count)/perPage)"
      ></v-pagination>
    </div>
    </v-sheet>
  </v-container>
</template>


<script>
const debounce = function (f, ms) {
  let timer = null;
  return function (...args) {
    const onComplete = () => {
      f.apply(this, args);
      timer = null;
    };
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(onComplete, ms);
  };
};
export default {
  data() {
    return {
      searchString: "",
      experts: [],
      perPage: 10,
      count: 0,
      page: 1,
      loading: true,
      headers: [
        { text: "Номер регистрации в реестре", value: "regNum" },
        { text: "Ф.И.О. эксперта", value: "name" },
        { text: "Виды деятельности", value: "expertsKindOfActivities" },
        { text: "Направление деятельности", value: "expertsCertificates" },
        { text: "Действия", value: "actions" },
      ],
    };
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$http
        .get("experts-reg/list", {
          params: {
            perPage: this.perPage,
            page: this.page,
            search: this.searchString,
          },
        })
        .then((response) => {
          this.experts = response.data.experts;
          this.count = parseInt(response.data.count);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search: debounce(function () {
      this.page = 1;
      this.fetchData();
    }, 500),
  },
  watch: {
    perPage() {
      this.fetchData();
    },
    page() {
      this.fetchData();
    },
    searchString() {
      this.search();
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style>
</style>