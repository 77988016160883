var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',[_c('thead',{staticClass:"text-body-2 text-left"},[_c('tr',[_c('th',[_c('v-tooltip',{attrs:{"location":"top"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var props = ref.props;
return [_c('div',_vm._b({},'div',props,false),[_vm._v("Основание")])]}}])},[_vm._v(" Основание для выдачи квалификационного аттестата ")])],1),_c('th',[_c('v-tooltip',{attrs:{"location":"top"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var props = ref.props;
return [_c('div',_vm._b({},'div',props,false),[_vm._v("Регистрационный номер")])]}}])},[_vm._v(" Регистрационный номер квалификационного аттестата ")])],1),_c('th',[_c('v-tooltip',{attrs:{"location":"top"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var props = ref.props;
return [_c('div',_vm._b({},'div',props,false),[_vm._v("Дата выдачи")])]}}])},[_vm._v(" Дата выдачи квалификационного аттестата ")])],1),_c('th',[_c('v-tooltip',{attrs:{"location":"top"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var props = ref.props;
return [_c('div',_vm._b({},'div',props,false),[_vm._v("Срок действия")])]}}])},[_vm._v(" Срок действия квалификационного аттестата ")])],1),_c('th',{staticClass:"d-flex align-center justify-space-evenly"},[_c('div',[_vm._v(" Направление деятельности ")]),_vm._v(" "),_c('div',[_vm._v("Область")])])])]),_c('tbody',{staticClass:"text-left text-body-2"},_vm._l((_vm.kind.expertsCertificates),function(cert,index){return _c('tr',{key:index,class:new Date(cert.validityDate).getTime() < new Date().getTime() ? 'grey lighten-2 text-grey' : ''},[_c('td',[_vm._v(_vm._s(cert.base))]),_c('td',[_vm._v(_vm._s(cert.regNum))]),_c('td',[_vm._v(_vm._s(_vm._f("moment")(cert.issueDate," DD.MM.YYYY")))]),_c('td',[_vm._v(_vm._s(_vm._f("moment")(cert.validityDate," DD.MM.YYYY ")))]),_c('td',_vm._l((cert.lines),function(line,indexL){return _c('div',{key:indexL},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"mb-1 pa-1 white--text",attrs:{"rounded":"lg","variant":"tonal","color":"primary"}},[_vm._v(_vm._s(line.name))])],1),_c('v-col',{attrs:{"cols":"6"}},_vm._l((line.fields),function(field,indexL){return _c('div',{key:indexL},[_c('div',[_c('v-card',{staticClass:"mb-1 pa-1 white--text",attrs:{"outlined":"","color":"primary"}},[_vm._v(_vm._s(field))])],1)])}),0)],1),(cert.lines[indexL + 1])?_c('v-divider',{staticClass:"my-1"}):_vm._e()],1)}),0)])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }