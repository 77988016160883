<template>
  <v-container>
    <v-card elevation="12" rounded class="pa-3" v-if="!loading">
      <v-card-title>
        {{ expert.name }}
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-subtitle  class="pb-0">
        Номер регистрации в реестре {{ expert.regNum }}
      </v-card-subtitle>
      <v-card-text>



<!--        <div v-for="line in expert.expertsLines" :key="line.id">-->
<!--          <expertLine :line="line" />-->
<!--        </div>-->


        <div v-for="kind in expert.expertsKindOfActivities" :key="kind.id">
          <v-card elevation="10 mb-5" rounded="lg" >
            <v-card-title class="d-flex align-center justify-space-between">
              {{kind.name}}
            </v-card-title>
            <v-divider/>
            <v-card-text>
              <ExpertsCertsNew  :kind="kind"/>
            </v-card-text>
          </v-card>
        </div>


      </v-card-text>
    </v-card>
    <v-skeleton-loader
      v-else
      class="mx-auto"
      type="card-title, card"
    ></v-skeleton-loader>
  </v-container>
</template>

<script>
// import expertLine from "./expertLine.vue";
import ExpertsCertsNew from "@/views/ExpertsCertsNew.vue";

export default {
  data() {
    return {
      loading: true,
      expert: {},
    };
  },
  components: {
    ExpertsCertsNew
    // expertLine,
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$http
        .get("experts-reg/view", {
          params: {
            id: this.$route.params.id,
          },
        })
        .then((response) => {
          this.expert = response.data;
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style>
</style>
