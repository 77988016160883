<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="/logo.png"
          transition="scale-transition"
          width="40"
        />

        <div class="text-h5" v-if="!$vuetify.breakpoint.mobile">Реестр экспертов СПК ЧС</div>
      </div>

      <v-spacer></v-spacer>
      <v-btn href="https://spkchs.ru" text outlined class="ma-3">Сайт СПК ЧС</v-btn>
  <v-btn to="/" v-if="$route.params.id" color="green" dark>Список экспертов</v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
    head: {
       link: [
             {
                 rel: "icon",
                 href: "favicon.ico"
             },
          ]
       },
  data: () => ({
    //
  }),
};
</script>
