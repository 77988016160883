var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-sheet',{staticClass:"pa-3",attrs:{"elevation":"12","rounded":""}},[_c('v-card-title',[_vm._v(" Список экспертов "),_c('v-spacer'),_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-text-field',{staticClass:"pr-3",attrs:{"append-icon":"mdi-magnify","label":"Поиск","single-line":"","hide-details":""},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}})],1)],1),_c('v-data-table',{staticClass:"border",attrs:{"disable-sort":"","items":_vm.experts,"loading":_vm.loading,"server-items-length":_vm.count,"items-per-page":_vm.perPage,"headers":_vm.headers,"page":_vm.page,"loading-text":"Загрузка... Пожалуйста подождите","no-data-text":"Результатов нет","no-results-text":"Результатов нет","footer-props":{
        'items-per-page-text': 'Количество',
        'items-per-page-options': [5, 10, 20,100],
      }},on:{"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":function($event){_vm.perPage=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(ref){
      var pageStart = ref.pageStart;
      var pageStop = ref.pageStop;
      var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+"-"+_vm._s(pageStop)+" из "+_vm._s(itemsLength)+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" Нет элементов ")]},proxy:true},{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.expertsKindOfActivities",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-left"},_vm._l((item.expertsKindOfActivities),function(kind){return _c('v-list-item',{key:kind.id},[_vm._v(" "+_vm._s(kind.name)+" ")])}),1)]}},{key:"item.expertsCertificates",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-left"},_vm._l((item.expertsKindOfActivities),function(kind){return _c('div',{key:kind.id},_vm._l((kind.expertsCertificates),function(serts){return _c('v-list-item',{key:serts.id},[_vm._v(" "+_vm._s(serts.lines.map(function (el) { return el.name; }).join(', '))+" ")])}),1)}),0)]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"to":'/' + item.id,"color":"green","dark":""}},[_vm._v("Перейти")])],1)]}},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"table-tbody"}})]},proxy:true}])}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"total-visible":15,"length":Math.ceil(parseInt(_vm.count)/_vm.perPage)},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }