<template>
  <div>
    <v-simple-table >
      <thead class="text-body-2 text-left">
      <tr>
        <th>
          <v-tooltip location="top">
            <template #activator="{props}">
              <div v-bind="props">Основание</div>
            </template>
            Основание для выдачи квалификационного аттестата
          </v-tooltip>
        </th>
        <th>
          <v-tooltip location="top">
            <template #activator="{props}">
              <div v-bind="props">Регистрационный номер</div>
            </template>
            Регистрационный номер квалификационного аттестата
          </v-tooltip>
        </th>
        <th>
          <v-tooltip location="top">
            <template #activator="{props}">
              <div v-bind="props">Дата выдачи</div>
            </template>
            Дата выдачи квалификационного аттестата
          </v-tooltip>
        </th>
        <th>
          <v-tooltip location="top">
            <template #activator="{props}">
              <div v-bind="props">Срок действия</div>
            </template>
            Срок действия квалификационного аттестата
          </v-tooltip>
        </th>
        <th class="d-flex align-center justify-space-evenly">
          <div>  Направление деятельности </div> <div>Область</div>
        </th>

      </tr>
      </thead>
      <tbody class="text-left text-body-2">
      <tr v-for="(cert, index) in kind.expertsCertificates" :key="index" :class="new Date(cert.validityDate).getTime() < new Date().getTime() ? 'grey lighten-2 text-grey' : ''">
        <td>{{ cert.base }}</td>
        <td>{{ cert.regNum }}</td>
        <td>{{ cert.issueDate | moment(" DD.MM.YYYY")}}</td>
        <td>{{ cert.validityDate | moment(" DD.MM.YYYY ") }}</td>
        <td>
          <div v-for="(line, indexL) in cert.lines" :key="indexL">
            <v-row  align="center">
              <v-col cols="6">
                <v-card  rounded="lg" variant="tonal" color="primary" class="mb-1 pa-1 white--text">{{line.name}}</v-card>
              </v-col>
              <v-col cols="6">
                <div v-for="(field, indexL) in line.fields" :key="indexL">
                  <div>
                    <v-card class="mb-1 pa-1 white--text" outlined  color="primary" >{{field}}</v-card>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-divider v-if="cert.lines[indexL + 1]" class="my-1" />
          </div>

        </td>


      </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "ExpertsCertsNew",
  props: ['kind'],
}
</script>



<style scoped>

</style>